import "jquery";
import "bootstrap";

/**
 * Window Onload Queue
 * 
 * @param {function} nextFunc 
 */
 const addOnload = nextFunc => {
    if( typeof window.onload != 'function' ) {
        window.onload = nextFunc
    } else {
        let func = window.onload
        
        window.onload = () => {
            if( func ) {
                func()
            }

            nextFunc()
        }
    }
}

window.addOnload = addOnload

// Make jQuery mobile event listeners passive
jQuery.event.special.touchstart = {
    setup: function( _, ns, handle ) {
        this.addEventListener("touchstart", handle, { passive: !ns.includes("noPreventDefault") });
    }
};
jQuery.event.special.touchmove = {
    setup: function( _, ns, handle ) {
        this.addEventListener("touchmove", handle, { passive: !ns.includes("noPreventDefault") });
    }
};
jQuery.event.special.wheel = {
    setup: function( _, ns, handle ){
        this.addEventListener("wheel", handle, { passive: true });
    }
};
jQuery.event.special.mousewheel = {
    setup: function( _, ns, handle ){
        this.addEventListener("mousewheel", handle, { passive: true });
    }
};

!(($) => {
	const debug = false;
	const env = new Map([["www", 'www'], ["staging", 'staging'], ["chemtreat", 'chemtreat']]),
		register = [],
		log = (arg = []) => {
			if (debug) {
				if (!Array.isArray(arg)) {
					arg = [arg];
				}
				console.log(...arg);
			}
		};

	window.log = log;

	// Is language cookie secure 
	let requireSecure = !/staging|localhost|test/.test(document.location.href) || false;
	let cookieDomain = '.chemtreat.com';
	let siteDomain = 'www.chemtreat.com';

	// Determine cookie domain
	switch( true ) {
		case /localhost/.test(document.location.href):
			cookieDomain = '.chemtreat.localhost';
			siteDomain = 'chemtreat.localhost';
			break;
		
		case /test/.test(document.location.href):
			cookieDomain = '.chemtreat.test';
			siteDomain = 'chemtreat.test';
			break;

		case /staging/.test(document.location.href):
			cookieDomain = '.chemtreat.com';
			siteDomain = 'staging.chemtreat.com';
			break;
	}

	log(['Requires Secure', requireSecure]);
	log(['site Domain', siteDomain]);

	// Set language cookie params
	const cht_language_params = {
		set_onload: true,
		redirect_onload: true,
		redirect_onselect: true,
		load_same_page: true,
		cookie_expire: 7,
		cookie_same_site: 'strict',
		cookie_domain: cookieDomain,
		cookie_path: '/',
		cookie_secure: requireSecure
	};

	// Map language data
	const languageData = new Map();

	// Default
	languageData.set('defaultLanguage', {
		display_name: "USA - English",
		site_domain: siteDomain
	});

	// English
	languageData.set('en', languageData.get('defaultLanguage'));

	// Spanish
	languageData.set('es', {
		display_name: "Latin America - Español",
		site_domain: "es.chemtreat.com"
	});

	// French
	languageData.set('fr', {
		display_name: "Canada - French",
		site_domain: "fr.chemtreat.com"
	});

	// Portugues
	languageData.set('pt', {
		display_name: "Brazil - Português",
		site_domain: "pt.chemtreat.com"
	});

	// Register language menu function
	register.push((lang) => {
		let toggled = 1;
		const parent = $(".language-selector");
		const langToggle = $(".selector", parent);
		const langName = $(".name", parent);
		const langTarget = $(".regions", parent);
		const langSelectors = $(".language", langTarget);

		// Display current language name
		langName.html(
			languageData.get(lang) ? languageData.get(lang).display_name :
			languageData.get('defaultLanguage').display_name
		);

		// Toggle the language menu
		langToggle.on({
			click: function (e) {
				e.preventDefault();

				if (toggled % 2 === 0) {
					parent.removeClass("open");
				} else {
					parent.addClass("open");
				}

				toggled++;
			},
		});

		// Handle language selection
		langSelectors.on({
			click: function (e) {
				e.preventDefault();

				// Close the language menu always
				toggled++;
				langToggle.trigger('click');

				// Language key is following pattern ([\w]{2}(-[\w]*)?)
				const [lang, locale] = $(this).attr("data-key").split("-");

				// Exit on current language
				if (lang === window.lang) {
					return;
				}

				// Set language name element
				langName.html(languageData.get(lang).display_name);
				window.lang = lang;
				toggled++;

				// Set cookie for selected language
				const myCookie = new Cookie();
				myCookie.set('lang', lang, cht_language_params.cookie_expire);

				if( !cht_language_params.redirect_onselect ) {
					return false;
				}

				// Go to selected language domain
				languageRedirect(lang);
			},
		});
	});

	// Localization Handler
	register.push((lang) => {
		const selector = `*[data-lang=${lang}]`,
			element = $(selector);

		log([lang, element]);
		element.removeClass("hide");
		$('.localized-item').not(element).addClass("hide");
	});

	register.push((lang) => {
		$(function () {
			const videoPlayBtnContainer = $(".video-play-btn");
			const videoPlayBtn = $("figure a", videoPlayBtnContainer);

			if (!videoPlayBtn.length) {
				return;
			}

			// This plugin seems to require an initialization step to show the video on first button click
			videoPlayBtn.magnificPopup({ type: "iframe" });

			videoPlayBtn.on({
				click: function (e) {
					e.preventDefault();
					$(this).magnificPopup({ type: "iframe" });
				},
			});
		});
	});

	const Params = new Proxy(new URLSearchParams(window.location.search), {
		get: (searchParams, prop) => searchParams.get(prop),
	});

	function Cookie() {
		return {
			get: key => {
				const cookieDecoded = decodeURIComponent(document.cookie);
				const cookieData = cookieDecoded.split('; ');
				let resp = false;

				for( let i = 0; i < cookieData.length; i++ ) {
					const data = cookieData[i].split('=');
					
					if( data[0] == key ) {
						resp = data[1];
						break;
					}
				}

				return resp;
			},

			set: (key, val, exp = 0) => {
				const date = new Date(Date.now() + (exp*24*3600*1000));
				date.setUTCHours(0);
				date.setUTCMinutes(0);
				date.setUTCSeconds(0);

				log(['Date', date.toUTCString()]);

				document.cookie = `${key}=${val};domain=${cht_language_params.cookie_domain};samesite=${cht_language_params.cookie_same_site};expires=${date.toUTCString()};path=${cht_language_params.cookie_path};${cht_language_params.cookie_secure ? 'secure;' : ''}`;
			},

			delete: key => {
				const date = new Date(Date.now() - (10*24*3600*1000));
				document.cookie = `${key}=; expires=${date.toUTCString()}; path=${cht_language_params.cookie_path}`;
			}
		};
	}

	// Handle language redirects
	function languageRedirect(lang) {
		let page = '', search = '', hash = '';

		if( cht_language_params.load_same_page ) {
			page = document.location.pathname || page;
			search = document.location.search ? `?${document.location.search}` : search;
			hash = document.location.hash || hash; 
		}

		log(['Redirect', `//${languageData.get(lang).site_domain}${page}${hash}${search}`]);

		location.href = `//${languageData.get(lang).site_domain}${page}${hash}${search}`;
	}

	// Start the process
	!(() => {
		// Extract language from search param or from the URL
		// Values extracted here take presidence over cookies
		let lang = Params.lang;

		log(['Lang From Query Param', lang]);

		lang ||
			(lang = document.location.href
				.split("//")
				.pop()
				.split(".")
				.shift());
		// lang && env.join("").indexOf(lang) > -1 && (lang = "en");

		log(['Lang from Domain', lang]);
		let current_lang = lang;
		const myCookie = new Cookie();
		window.Cookie = myCookie;
		window.Params = Params;

		log('Params', Params.lang);

		// Handle Language Cookie only if there is 
		// no valid language extracted from above
		if( env.has(lang) || lang == '' ) {
			current_lang  = 'en';
			let myLang = myCookie.get('lang');

			if( myLang && cht_language_params.set_onload ) {
				lang = myLang;
			}
			else {
				lang = 'en';
			}
		}

		// Set language cookie
		myCookie.set('lang', lang, cht_language_params.cookie_expire);

		// Redirect only if this is a different language
		if( lang != current_lang && cht_language_params.redirect_onload ) {
			languageRedirect(lang);
		}

		window.lang = lang;

		// Wait... to increase Page Speed scores
		addOnload(() => {
			register.forEach((func) => func(lang));
		});
		
	})();
})(jQuery);

require("./nav");
