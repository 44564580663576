$ = window.jQuery;
// CREATE APP
var APP = (window.APP = window.APP || {});

var $debug = false;

APP.Nav = (function () {
	var timer,
		$body,
		$intFrameWidth,
		$navTrigger,
		$nav,
		$navParent,
		$navChild,
		$resWidth,
		$initWidth,
		$subclose,
		$searchToggle,
		$mainSearch,
		$cancelSearch;

	var initEvents = function () {
		$navTrigger.on("click", function () {
			if ($(this).hasClass("open")) {
				clearNav();
			} else {
				setNav();
			}
		});
		$navParent.on("click", function (e) {
			$this = $(this);
			if ($resWidth < $initWidth) {
				e.preventDefault();
				setSubNav($this);
			}
		});
		$subclose.on("click", function (e) {
			e.preventDefault();
			closeSubNav($this);
		});
		$searchToggle.on("click", function () {
			clearNav();
			closeSubNav();
			setSearch();
		});

		$mainSearch.on( 'input change', function () {
			var $val = $(this).val();
			if ($val != '') {
				$(this).parent().addClass("active");
			} else {
				$(this).parent().removeClass("active");
			}
		});
		$cancelSearch.on('click', function () {
			$mainSearch.val(null).trigger("change");
		});
	};

	var setSearch = function () {
		$body.addClass("search-open");
		$navTrigger.addClass("open");
		$lang.removeClass("open");
	};

	var cancelSearch = function () {
		$body.removeClass("search-open");
	};

	var setSubNav = function (par) {
		par.parent().find($navChild).addClass("active");
		$body.addClass("mobile-subnav-active");
		$lang.removeClass("open");
	};

	var closeSubNav = function (par) {
		$navChild.removeClass("active");
		$body.removeClass("mobile-subnav-active");
	};

	var setNav = function () {
		$navTrigger.addClass("open");
		$body.addClass("mobile-nav-active");
	};

	var clearNav = function () {
		$navTrigger.removeClass("open");
		$body.removeClass("mobile-nav-active");
		closeSubNav();
		cancelSearch();
		$lang.removeClass("open");
	};

	$(window).on("load resize orientationchange", function () {
		clearTimeout(timer);
		timer = setTimeout(function () {
			$resWidth = $(window).innerWidth();
			if ($resWidth >= $initWidth) {
				clearNav();
			}
		}, 200);
	});

	var init = function () {
		if ($debug) {
			console.log("APP.MobileNav");
		}
		$intFrameWidth = $(window).innerWidth();
		$navTrigger = $(".nav-trigger");
		$mobNav = $(".mobile-nav .mobileMainNav");
		$navParent = $mobNav.find(".mobile-dropdown > a");
		$navChild = $(".mobile-dropdown-menu");
		$subclose = $(".subclose");
		$searchToggle = $(".search-toggle");
		$body = $("body");
		$lang = $(".language-selector");
		$initWidth = 992;
		$mainSearch = $('.main-nav .form-wrap form input[type="search"]');
		$cancelSearch = $('.cancelSearch');
		initEvents();
	};

	return {
		init: init,
	};
})();

document.addEventListener("DOMContentLoaded", function () {
	APP.Nav.init();
});
